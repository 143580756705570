import {
  APPLICATION_INITIALISED,
  APPLICATION_SET_LOADING,
  APPLICATION_SET_ERROR,
  PUSHER_ACTIVATE,
  PUSHER_DEACTIVATE,
  PUSHER_ACTIVATED,
  PUSHER_DEACTIVATED,
  AUTH_SET,
  AUTH_REQUEST,
  SET_MONITORS,
  SET_PINGS,
  SET_SITES,
} from '@types'

export const appIsInitialised = () => ({
  type: APPLICATION_INITIALISED,
  payload: {},
})

export const appSetLoading = () => ({
  type: APPLICATION_SET_LOADING,
  payload: {},
})

export const appSetError = (type, props = {}) => ({
  type: APPLICATION_SET_ERROR,
  payload: { type, props },
})

/**
 * Auth
 */
export const requestAuthentication = (username, password) => ({
  type: AUTH_REQUEST,
  payload: { username, password },
})

export const setAuthenticated = (bool = false, token = null) => ({
  type: AUTH_SET,
  payload: { authenticated: bool, token },
})

/**
 * Setters
 */

export const setSites = data => ({
  type: SET_SITES,
  payload: data,
})

export const setMonitors = data => ({
  type: SET_MONITORS,
  payload: data,
})

export const setPings = data => ({
  type: SET_PINGS,
  payload: data,
})

/**
 * Pusher
 */
export const activatePusher = () => ({
  type: PUSHER_ACTIVATE,
  payload: {},
})

export const deactivatePusher = () => ({
  type: PUSHER_DEACTIVATE,
  payload: {},
})

export const pusherActivated = () => ({
  type: PUSHER_ACTIVATED,
  payload: {},
})

export const pusherDeactivated = () => ({
  type: PUSHER_DEACTIVATED,
  payload: {},
})
