import Sites from '@components/Modules/Sites'
import styles from './Body.scss'

const Body = () => (
  <div className={styles.base}>
    <Sites />
  </div>
)

Body.defaultProps = {}
Body.propTypes = {}

export default Body
