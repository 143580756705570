import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import 'whatwg-fetch'
// import qs from 'qs'
import { call } from '@api'
// import config from '@config'

export const useApi = ([url, options]) => {
  const [results, setResults] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)

    const controller = call(
      url,
      options,
      json => {
        setResults(json)
        setError(null)
        setLoading(false)
      },
      err => {
        setError(err)
        setLoading(false)
      }
    )

    return () => {
      if (controller) {
        controller.abort()
      }
    }
  }, [url, options])

  return [results, error, loading]
}

export const useGetSiteMonitors = siteId =>
  useSelector(s =>
    Object.values(s.monitor).filter(({ relationships }) => relationships.site.data.id === siteId)
  )

export const useGetMonitorPings = monitorId => {
  const monitor = useSelector(s => s.monitor[monitorId])
  const pings = useSelector(s => s.ping)
  return monitor?.relationships?.pings?.data.map(({ id }) => pings[id]).filter(v => v !== undefined)
}
