import { SET_MONITORS } from '@types'

export const initialState = {}

export default (state = initialState, { type, payload = {} } = {}) => {
  switch (type) {
    case SET_MONITORS:
      return { ...state, ...payload }

    default:
      return state
  }
}
