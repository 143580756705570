const def = {
  cluster: 'eu',
  key: '8a0b00fad02ebc917b23',
}

if (process.env.NODE_ENV === 'production') {
  def.key = 'c969ef861e7ba4cb398d'
}

export default def
