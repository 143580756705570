import classNames from 'classnames'
import Text from '@components/Ui/Text'
import styles from './Badge.scss'

const Badge = ({ children, theme }) => (
  <Text as="span" type="text-s" className={classNames(styles.base, styles[`theme-${theme}`])}>
    {children}
  </Text>
)

Badge.defaultProps = {
  theme: 'default',
}
Badge.propTypes = {}

export default Badge
