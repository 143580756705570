import { fork } from 'redux-saga/effects'
import app from '@sagas/app'
import auth from '@sagas/auth'
import pusher from '@pusher'

// eslint-disable-next-line func-names
export default function*() {
  yield fork(app)
  yield fork(auth)
  yield fork(pusher)
}
