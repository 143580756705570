/* global process */
const def = {
  protocol: 'http',
  host: 'localhost',
  port: 7502,
  path: '/',
}

if (process.env.NODE_ENV === 'production') {
  def.protocol = 'https'
  def.host = 'api.probots.io'
  delete def.port
}

export default def
