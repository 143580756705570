import { all, takeEvery, put } from 'redux-saga/effects'
import { AUTH_REQUEST, AUTH_SET, APPLICATION_INITIALISED } from '@types'
import { callPromise, postLogin, postRefresh } from '@api'
import { setAuthenticated } from '@store/actions/app'
import { fetchSiteMonitors } from '@store/sagas/app'
import config from '@config'

const handleRequestLogin = function*({ payload }) {
  const { username, password } = payload

  const [url, options] = postLogin(username, password)
  const response = yield callPromise(url, options)

  if (response.success === false) {
    yield put(setAuthenticated(false))
  } else {
    const token = response?.bearer?.token

    if (token) {
      yield put(setAuthenticated(true, token))
    }
  }
}

const handleAuthSet = function*({ payload }) {
  if (payload.authenticated) {
    yield localStorage.setItem(config.get('auth.key'), payload.token)
    yield fetchSiteMonitors()
  } else {
    yield localStorage.removeItem(config.get('auth.key'))
  }
}

const handleApplicationInitialised = function*({ payload }) {
  const token = yield localStorage.getItem(config.get('auth.key'))

  if (token) {
    const [url, options] = postRefresh()
    const response = yield callPromise(url, options)

    if (response.success === false) {
      yield put(setAuthenticated(false))
    } else {
      const bearer = response?.bearer?.token

      if (bearer) {
        yield put(setAuthenticated(true, bearer))
      }
    }
  }
}

// eslint-disable-next-line func-names
export default function*() {
  yield all([
    takeEvery(AUTH_REQUEST, handleRequestLogin),
    takeEvery(AUTH_SET, handleAuthSet),
    takeEvery(APPLICATION_INITIALISED, handleApplicationInitialised),
  ])
}
