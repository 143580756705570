import { useState, useEffect } from 'react'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import classNames from 'classnames'
import Chart from '@components/Modules/Chart'
import Spacer from '@components/Ui/Spacer'
import Text from '@components/Ui/Text'
import Badge from '@components/Ui/Badge'
import { useGetSiteMonitors } from '@hooks'
import styles from './Site.scss'

const getTimingTheme = time => {
  if (time > 2000) return 'danger'
  if (time > 1000) return 'warning'
  return 'success'
}

const getStatusTheme = status => {
  if (status === 200) return 'success'
  return 'danger'
}

const Site = ({ site }) => {
  const monitors = useGetSiteMonitors(site.id)

  const monitor = monitors[0]
  const [show, toggleShow] = useState(false)

  const monitorAttr = monitor?.attributes || null
  const siteAttr = site?.attributes || null

  useEffect(() => {
    if (monitorAttr && monitorAttr?.status !== 200) {
      toggleShow(true)
    }
  }, [monitorAttr?.status])

  if (!monitorAttr || !siteAttr) {
    return null
  }

  const name = show ? siteAttr?.name : siteAttr?.shortcut
  const fromNow = formatDistanceToNow(Date.parse(monitorAttr.updatedAt))
  const lastTiming = Math.round(monitorAttr.timings.total)
  const intervalInMinutes = monitorAttr.interval / 60
  const url = show ? monitorAttr.host + monitorAttr.path : monitorAttr.path

  return (
    <div
      className={classNames(styles.base, {
        [styles.alert]: monitorAttr.status !== 200,
      })}
      onClick={() => toggleShow(!show)}
    >
      <div className={styles.header}>
        <Text as="div" type="caption-large">
          {monitorAttr.timings.total > 1000 && '👀 '}
          {monitorAttr.status !== 200 ? `🔥 ${name}` : name}
        </Text>
        <div className={styles.flex}>
          <Badge theme={getTimingTheme(monitorAttr.timings.total)}>{`${lastTiming} ms`}</Badge>
          <Spacer scale="0.5" />
          <Badge theme={getStatusTheme(monitorAttr.status)}>{monitorAttr.status}</Badge>
        </div>
      </div>

      <div className={styles.body}>
        <Chart monitor={monitor} />
      </div>

      <div className={styles.footer}>
        <Text as="div" type="text-s">
          {url}
        </Text>
        <div className={styles.flex}>
          <Badge>{fromNow}</Badge>
          <Spacer scale="0.5" />
          <Badge>{`${intervalInMinutes} m`}</Badge>
        </div>
      </div>
    </div>
  )
}

Site.defaultProps = {
  monitor: { attributes: {} },
  site: { attributes: {} },
}
Site.propTypes = {}

export default Site
