import classNames from 'classnames'
import PropTypes from 'prop-types'
import styles from './Spacer.scss'

const Spacer = ({ scale, debug, vertical }) => (
  <div
    className={classNames(styles.base, styles[`${vertical ? 'v' : 'h'}-${scale * 10}`], {
      [styles.debug]: debug,
    })}
  />
)

Spacer.defaultProps = {
  vertical: false,
  scale: '0.5',
  debug: false,
}
Spacer.propTypes = {
  vertical: PropTypes.bool,
  debug: PropTypes.bool,
  scale: PropTypes.oneOf(['0.2', '0.5', '1', '1.5', '2', '2.5', '3']),
}

export default Spacer
