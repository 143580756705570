import produce from 'immer'
import { APPLICATION_INITIALISED, PUSHER_ACTIVATED, PUSHER_DEACTIVATED } from '@types'

export const initialState = {
  initialised: false,
  pusher: false,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case APPLICATION_INITIALISED:
      return produce(state, s => {
        s.initialised = true
      })

    case PUSHER_ACTIVATED:
      return produce(state, s => {
        s.pusher = true
      })
    case PUSHER_DEACTIVATED:
      return produce(state, s => {
        s.pusher = false
      })

    default:
      return state
  }
}
