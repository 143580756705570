import Pusher from 'pusher-js'
import normalize from 'json-api-normalizer'
import { eventChannel } from 'redux-saga'
import { all, call, put, take, race } from 'redux-saga/effects'
import config from '@config'
import { PUSHER_ACTIVATE, PUSHER_DEACTIVATE } from '@types'
import { setMonitors, setPings, pusherActivated, pusherDeactivated } from '@store/actions/app'

function watch(socket) {
  return eventChannel(emit => {
    // listen to monitors
    const channelMonitors = socket.subscribe('account.1.monitors')

    channelMonitors.bind('monitor.ping.received', data => {
      const normalized = normalize(data.monitor)

      emit(setPings(normalized.siteMonitorPing))
      emit(setMonitors(normalized.siteMonitor))
    })

    channelMonitors.bind('monitor.refresh', () => {
      window.location.reload()
    })

    socket.connection.bind('connected', () => {
      emit(pusherActivated())
    })

    socket.connection.bind('unavailable', () => {
      emit(pusherDeactivated())
    })

    socket.connection.bind('failed', () => {
      emit(pusherDeactivated())
    })

    socket.connection.bind('disconnected', () => {
      emit(pusherDeactivated())
    })

    // // listen to activity
    // const channelActivity = socket.subscribe('account.1.activity')

    // channelActivity.bind('activity.created', data => {
    //   console.log('activity.created', data)
    //   emit(updateState(normalize(data.activity)))
    // })

    return () => socket.disconnect()
  })
}

function* recevier(socketChannel) {
  while (true) {
    const payload = yield take(socketChannel)
    yield put(payload)
  }
}

function* sender() {
  while (true) {
    yield take('X_PUSHER_SEND')
    // socketChannel.send(
    //   JSON.stringify({ type: 'setTask', task: data.data.taskid })
    // )
  }
}

export default function*() {
  while (true) {
    yield take(PUSHER_ACTIVATE)
    const socket = new Pusher(config.get('pusher.key'), {
      cluster: config.get('pusher.cluster'),
    })

    const socketChannel = yield call(watch, socket)

    const { cancel } = yield race({
      task: all([call(recevier, socketChannel), call(sender, socket)]),
      cancel: take(PUSHER_DEACTIVATE),
    })

    console.log('ddd')

    if (cancel) {
      socketChannel.close()
      yield put(pusherDeactivated())
    }
  }
}

// const echo = new Echo({
//   broadcaster: 'pusher',
//   cluster: 'eu',
//   key: '8a0b00fad02ebc917b23',
// })

// echo.channel('Account.1.Monitors').listen('SiteMonitorFailed', e => {
//   console.log(e)
// })
// // echo.private('monitors').listen('MonitorFailed', e => {
// //   console.log(e)
// // })
