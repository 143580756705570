import produce from 'immer'
import { AUTH_SET } from '@types'

export const initialState = {
  authenticated: false,
  token: null,
}

export default (state = initialState, { type, payload } = {}) => {
  switch (type) {
    case AUTH_SET:
      return produce(state, s => {
        s.authenticated = payload.authenticated
        s.token = payload.token
      })

    default:
      return state
  }
}
