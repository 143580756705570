import { useSelector } from 'react-redux'
import classNames from 'classnames'
import Text from '@components/Ui/Text'
import styles from './Header.scss'

const Header = () => {
  const pusherActive = useSelector(s => s.app.pusher)

  return (
    <div className={styles.base}>
      <Text type="logo" className={styles.logo}>
        probots
      </Text>
      <Text type="logo" className={styles.alt}>
        monitor
      </Text>

      <div className={styles.info}>
        <Text
          type="caption"
          className={classNames(styles.pusher, {
            [styles.active]: pusherActive,
          })}
        >
          LIVE
        </Text>
      </div>
    </div>
  )
}

Header.defaultProps = {}
Header.propTypes = {}

export default Header
