import 'whatwg-fetch'
import normalize from 'json-api-normalizer'
import qs from 'qs'
import config from '@config'

const createUrl = (path, query = {}) => {
  const apiConf = config.get('api')
  const apiBase = `${apiConf.protocol}://${apiConf.host}${apiConf.port ? `:${apiConf.port}` : ''}${
    apiConf.path
  }`

  return `${apiBase}${path}${qs.stringify(query, { addQueryPrefix: true })}`
}

export const call = (url, options, onSuccess = () => {}, onError = () => {}) => {
  const controller = new AbortController()
  options.signal = controller.signal

  const shouldNormalize = options.normalize !== false ? true : false
  const shouldAuthenticate = options.auth !== false ? true : false

  delete options.normalize
  delete options.auth

  options.headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  if (shouldAuthenticate) {
    options.headers.Authorization = `Bearer ${localStorage.getItem(config.get('auth.key'))}`
  }

  fetch(url, options)
    .then(async response => {
      if (response.ok) {
        const json = await response.json()

        if (json.success) {
          if (shouldNormalize) {
            onSuccess(normalize(json?.payload))
          } else {
            onSuccess(json?.payload)
          }
        } else {
          onError(json?.payload)
        }
      } else {
        const json = await response.json()
        onError(json)
      }
    })
    .catch(err => {
      onError(err.message)
    })

  return controller
}

export const callPromise = (url, options) =>
  new Promise(resolve => {
    call(
      url,
      options,
      json => {
        resolve(json)
      },
      error => {
        resolve({ success: false, error })
      }
    )
  })

export const getSites = () => [
  createUrl('/monitors', { include: 'site,pings:limit(20):order(id|desc)' }),
  {
    method: 'GET',
  },
]

export const postLogin = (username, password) => [
  createUrl('/auth/login', {}),
  {
    auth: false,
    normalize: false,
    method: 'POST',
    body: JSON.stringify({ username, password }),
  },
]

export const postRefresh = () => [
  createUrl('/auth/refresh', {}),
  {
    normalize: false,
    method: 'POST',
  },
]
