import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestAuthentication } from 'store/actions/app'
import Spacer from '@components/Ui/Spacer'
import Text from '@components/Ui/Text'
import config from '@config'
import styles from './Auth.scss'

const Auth = ({ children }) => {
  const dispatch = useDispatch()
  const authenticated = useSelector(s => s.auth.authenticated)
  const [password, setPassword] = useState('')

  const onClickLogin = () => {
    dispatch(requestAuthentication(config.get('auth.user'), password))
  }

  if (authenticated) {
    return children
  }

  return (
    <div className={styles.box}>
      <div className={styles.inner}>
        <Text as="div" type="logo" className={styles.logo}>
          probots
        </Text>
        <Spacer vertical scale="0.5" />
        <input
          type="password"
          className={styles.password}
          value={password}
          onChange={e => setPassword(e.target.value)}
        />
        <Spacer vertical scale="0.5" />
        <button type="button" onClick={onClickLogin} className={styles.button}>
          <Text type="button">Activate</Text>
        </button>
      </div>
    </div>
  )
}

Auth.defaultProps = {}
Auth.propTypes = {}

export default Auth
