import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Mousetrap from 'mousetrap'
import Site from '@components/Modules/Site'
import Badge from '@components/Ui/Badge'
import styles from './Sites.scss'

const Sites = () => {
  const perPage = 9
  const intervalSeconds = 15

  const sites = useSelector(s => s.site)

  const [items, setItems] = useState([])
  const [pages, setPages] = useState(0)
  const [page, setPage] = useState(1)

  const nextPage = () => {
    const nxt = page + 1
    setPage(nxt > pages ? 1 : nxt)
  }

  const previousPage = () => {
    const nxt = page - 1
    setPage(nxt < 1 ? pages : nxt)
  }

  useEffect(() => {
    Mousetrap.bind('right', nextPage)
    Mousetrap.bind('left', previousPage)

    return () => {
      Mousetrap.unbind('right')
      Mousetrap.unbind('left')
    }
  }, [page, pages, sites])

  useEffect(() => {
    const count = Object.keys(sites).length || 0
    setPages(Math.ceil(count / perPage))
  }, [sites])

  useEffect(() => {
    const start = (page - 1) * perPage
    setItems(Object.values(sites).slice(start, start + perPage))
  }, [sites, page, pages])

  useEffect(() => {
    const interval = setInterval(nextPage, intervalSeconds * 1000)
    return () => clearInterval(interval)
  }, [page, pages, sites])

  return (
    <div className={styles.base}>
      <div className={styles.page}>
        <Badge>{`${page} / ${pages}`}</Badge>
      </div>
      {items?.map(site => <Site key={site?.id} site={site} />)}
      {Array.from('X'.repeat(perPage - items.length)).map((i, k) => (
        <div key={i + k} />
      ))}
    </div>
  )
}

Sites.defaultProps = {}
Sites.propTypes = {}

export default Sites
