import { ResponsiveContainer, LineChart, Line, YAxis, XAxis } from 'recharts'
import { useGetMonitorPings } from '@hooks'
import format from 'date-fns/format'
import Text from '@components/Ui/Text'
// import config from '@config'
import styles from './Chart.scss'
// import connect from './connect'

const Chart = ({ monitor }) => {
  const pings = useGetMonitorPings(monitor.id)
  const data = pings
    .map(ping => ({
      time: format(Date.parse(ping?.attributes?.updatedAt), 'HH:mm'),
      value: ping.attributes.timings.total,
    }))
    .reverse()

  return (
    <Text type="chart" className={styles.base}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <XAxis
            height={10}
            domain={[0, 'dataMax']}
            dataKey="time"
            interval="preserveStartEnd"
            tickLine={false}
            tick={{ fill: '#84808f' }}
            tickSize={2}
            stroke="#84808f"
            tickCount={2}
          />
          <Line
            key="line"
            type="monotone"
            dataKey="value"
            stroke="#84808f"
            dot={false}
            strokeWidth={1}
          />
        </LineChart>
      </ResponsiveContainer>
    </Text>
  )
}

// class Chart extends PureComponent {
//   static propTypes = {}

//   constructor(props) {
//     super(props)
//     this.state = {}
//   }

//   renderLines() {
//     const { monitors } = this.props

//     return monitors.map((m, i) => (
//       <Line
//         key={m.id}
//         type="monotone"
//         dataKey={`m${i}`}
//         stroke={config.monitorColors[i]}
//         dot={false}
//         strokeWidth={1}
//       />
//     ))
//   }

//   render() {
//     const { data } = this.props

//     return (
//       <Background type="dark" className={styles.base}>
//         <ResponsiveContainer height={100}>
//           <LineChart data={data}>
// <YAxis
//   width={40}
//   domain={[0, 'auto']}
//   interval="preserveStartEnd"
//   tickLine={false}
//   tick={{ fill: '#475365' }}
//   tickSize={2}
//   stroke="#475365"
// />
// {this.renderLines()}
//           </LineChart>
//         </ResponsiveContainer>
//       </Background>
//     )
//   }
// }

export default Chart
