import { combineReducers } from 'redux'
import app from '@reducers/app'
import auth from '@reducers/auth'
import site from '@reducers/site'
import monitor from '@reducers/monitor'
import ping from '@reducers/ping'

export default combineReducers({
  app,
  auth,
  site,
  monitor,
  ping,
})
