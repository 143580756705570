export const APPLICATION_INITIALISED = 'APPLICATION/INITIALISED'
export const APPLICATION_SET_RUNNING = 'APPLICATION/RUNNING'
export const APPLICATION_SET_LOADING = 'APPLICATION/LOADING'
export const APPLICATION_SET_ERROR = 'APPLICATION/ERROR'

export const SET_SITES = 'SET/SITES'
export const SET_MONITORS = 'SET/MONITORS'
export const SET_PINGS = 'SET/PINGS'

export const PUSHER_ACTIVATE = 'PUSHER/ACTIVATE'
export const PUSHER_DEACTIVATE = 'PUSHER/DEACTIVATE'
export const PUSHER_ACTIVATED = 'PUSHER/ACTIVATED'
export const PUSHER_DEACTIVATED = 'PUSHER/DEACTIVATED'

export const AUTH_SET = 'AUTH/SET'
export const AUTH_REQUEST = 'AUTH/REQUEST'
